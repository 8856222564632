<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!--            <label>显示</label>-->
            <!--            <v-select-->
            <!--                v-model="limit"-->
            <!--                :options="limitOptions"-->
            <!--                :clearable="false"-->
            <!--                class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>条</label>-->
            <!--            <div class="demo-spacing-0">-->
            <!--              <b-alert-->
            <!--                  variant="primary"-->
            <!--                  show-->
            <!--              >-->
            <!--                <div class="alert-body" >-->
            <!--                  <span><strong>勾选产品，表示此产品需要出库</strong> </span>-->
            <!--                </div>-->
            <!--              </b-alert>-->
            <!--            </div>-->

          </b-col>


          <!-- Search -->
          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="6"-->
          <!--          >-->
          <!--            <div class="d-flex align-items-center justify-content-end">-->
          <!--              <b-form-input-->
          <!--                  v-model="searchKey"-->
          <!--                  class="d-inline-block mr-1"-->
          <!--                  placeholder="搜索关键字..."-->
          <!--              />-->
          <!--              <b-button-->
          <!--                  variant="primary"-->
          <!--                  :to="{ name: 'apps-salesoutbounditem-edit'}"-->
          <!--              >-->
          <!--                <span class="text-nowrap">添加</span>-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :order-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :order-desc.sync="isSortDirDesc"
          v-model="alldata"
      >

        <!-- Columns -->
        <!--        <template #cell(id)="data">-->
        <!--          <b-form-checkbox-->
        <!--              name="user-id"-->
        <!--              v-model="checked"-->
        <!--              :value="data.item"-->
        <!--          >-->
        <!--            #{{data.item.id}}-->
        <!--          </b-form-checkbox>-->
        <!--        </template>-->

        <!--        <b-form-checkbox-->
        <!--            name="check-button"-->
        <!--            switch-->
        <!--            inline-->
        <!--            id="is_gift"-->
        <!--            v-model="data.item.is_gift"-->
        <!--            value="1"-->
        <!--            unchecked-value="0"-->
        <!--        ></b-form-checkbox>-->


        <template #cell(name)="data">
          #{{ data.item.id }}{{ data.item.name }}{{ isGift(data.item.is_gift) }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '<br>'+'负责人：'+data.item.our_contact+
              '<br>'+'负责人电话：'+data.item.our_mobile+
              '<br>'+'地址：'+data.item.our_location+
              '</div>'"
              variant="success"
          />
        </template>

        <!-- Column: Type -->
        <template #cell(salesoutbounditem_type)="data">
          {{ getCodeLabel('salesoutbounditem_type', data.item.salesoutbounditem_type) }}
        </template>

        <template #cell(code)="data">
          <b-form-checkbox
              v-model="data.item.isSelect"
              value="1"
              unchecked-value="0"
              @change="changeSelect('isSelect',data.item.isSelect,data.index)"
          >
            {{data.item.ext.productcode }}
          </b-form-checkbox>

        </template>

        <template #cell(warestatus)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.warestatus)}`"
          >
            {{ getCodeLabel("stock_status", data.item.warestatus)}}
          </b-badge>
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormCheckbox, BAlert, BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second
} from '@core/utils/filter'
import salesoutbounditemUseList from './salesoutbounditemabortUseList'
import salesoutbounditemStore from '../salesoutbounditemStore'
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import storeStore from "@/views/apps/store/storeStore";
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {useToast} from "vue-toastification/composition";
import warehouselocationStore from "@/views/apps/warehouselocation/warehouselocationStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    flatPickr,
    BFormCheckbox,
    BAlert,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    useToast,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salesoutbounditem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    getChecked: function () {
      const array = []
      this.$forceUpdate()
      for (let i = 0; i < this.alldata.length; i++) {
        if (this.alldata[i].isSelect==1){
          array.push(this.alldata[i])
        }
      }
      return array
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      warehouseArray: [],
      warehouse: {},
      warehouseId: 0,
      storeArray: [],
      store: {},
      storeId: 0,
      qty: 0,
      flag: true,
      alldata:[],
    }
  },
  props: ["salesOutboundId"],
  setup(props) {
    const toast = useToast()
    // alert(props.outbound_id)
    // Register module
    if (!store.hasModule('salesoutbounditem')) store.registerModule('salesoutbounditem', salesoutbounditemStore)
    if (!store.hasModule('warehouse')) store.registerModule("warehouse", warehouseStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesoutbounditem')) store.unregisterModule('salesoutbounditem')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
    })

    const changeIsGit = function (res) {
      if (res === 0) {
        return "否"
      } else {
        return "是"
      }
    }

    const edit = function () {

    }

    const changeDeliveryTime = function (val, event) {
      val.delivery_time = event
    }

    const changeArrivalTime = function (val, event) {
      val.arrival_time = event
    }

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const changeSelect = function (params,event,count) {
      this.alldata[count][params]=event
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salesoutbounditemUseList(props.salesOutboundId)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      second,
      // UI
      changeIsGit,
      edit,
      changeDeliveryTime,
      changeArrivalTime,
      isGift,
      changeSelect,

    }
  },
  created() {
    this.edit()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
