import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function salesoutboundUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {key: 'show_details', label: '详细内容', sortable: true},
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'outbound_no', label: '出库单编号', sortable: true },
    // { key: 'order_id', label: '销售单ID', sortable: true },
    { key: 'order_no', label: '销售单编号', sortable: true },
    { key: 'channel_name', label: '客户单位'},
    { key: 'delivery_type', label: '物流方式', sortable: true },
    // { key: 'transport_type', label: '运输方式', sortable: true },
    // { key: 'attach', label: '附件ID', sortable: true },
    // { key: 'memo', label: '备注', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建日期', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'status', label: '出库状态', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
    { key: 'business_time', label: '销售日期', sortable: true },
    //{ key: 'outbound_creator', label: '出库人', sortable: true },
    // { key: 'business_time', label: '业务时间', sortable: true },
    // { key: 'is_finance', label: '财务审核', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('outbound_id')
  const isSortDirDesc = ref(true)
  const orderNo = ref('')
  const productCode = ref('')
  const channelName = ref('')
  const statusId = ref('')
  const productName = ref('')
  const creatorId = ref('')
  const outboundNo = ref('')
  const business_time = ref('')
  const add_time = ref('')
  const warehouseId = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('salesoutbound/warehouselist', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        outbound_id:config.outbound_id,
        productCode:productCode.value,
        channelName:channelName.value,
        orderNo:orderNo.value,
        statusId:  statusId != null ? statusId.value.value : "",
        productName:productName.value,
        creatorId:creatorId.value,
        outboundNo:outboundNo.value,
        businessTime:business_time.value,
        addTime:add_time.value,
        warehouseId:warehouseId.value,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    productCode, orderNo,statusId,productName,creatorId,outboundNo,business_time,channelName,add_time,warehouseId,
    refetchData,

    // Extra Filters
  }
}
