<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="出库单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="outboundNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="productCode"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="productCode"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="productName"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="创建人:"
                        label-for="creator_name"
                        label-size="sm"
                >
                  <b-form-input
                          id="creator_name"
                          size="sm"
                          v-model="creatorName"
                          @click="showSingleModal('创建人')"
                          readonly
                          placeholder="点击搜索创建人"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="状态:"
                    label-for="status_id"
                    label-size="sm"
                >
                  <v-select
                      id="status_id"
                      :options="getCodeOptions('sale_outbound_status')"
                      :clearable="true"
                      v-model = "statusId"
                      class="select-size-sm"
                      placeholder="选择出库状态"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售日期:"
                    label-for="length"
                    label-size="sm"
                    class="mb-1"
                >
                  <flat-pickr
                      v-model="business_time"
                      class="form-control"
                      :config="{ mode: 'range'}"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建日期:"
                    label-for="length"
                    label-size="sm"
                    class="mb-1"
                >
                  <flat-pickr
                      v-model="add_time"
                      class="form-control"
                      :config="{ mode: 'range'}"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="客户单位:"
                        label-for="channelName"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="channelName"
                          class="d-inline-block mr-1"
                          size="sm"
                          aria-placeholder="ll"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库名称:"
                    label-for="warehouseName"
                    label-size="sm"
                >
                  <b-form-input
                      id="warehouseName"
                      size="sm"
                      v-model="warehouseName"
                      @click="showSingleModal('仓库')"
                      readonly
                      placeholder="点击搜索仓库"
                  />
                </b-form-group>
              </b-col>


              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
      >
        <!-- Columns 显示隐藏详细信息 -->

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>



                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                  <feather-icon
                      icon="CpuIcon"
                      size="21"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      @click="function () {
                      stockOccupied.warehouse_id=data.item.ourwarehouse_id
                      stockOccupied.product_id=data.item.product_id
                      stockOccupied.stock_id=data.item.stock_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
                  />
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Columns -->
        <template #cell(outbound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.outbound_no }}
        </template>

        <template #cell(delivery_type)="data">
          {{ getCodeLabel("delivery_method_sale", data.item.delivery_type) }}
        </template>

        <template #cell(transport_type)="data">
          {{ getCodeLabel("transport_type", data.item.transport_type) }}
        </template>

        <!-- Column: Type -->
        <template #cell(salesoutbound_type)="data">
          {{ getCodeLabel('salesoutbound_type', data.item.salesoutbound_type) }}
        </template>

        <template #cell(status)="data">
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('sale_outbound_status', data.item.status)}`"
          >
            {{ getCodeLabel('sale_outbound_status', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel("user", data.item.creator) }}
        </template>

        <template #cell(outbound_time)="data">
          {{ toDate(data.item.outbound_time) }}
        </template>

        <template #cell(outbound_creator)="data">
          {{ getCodeLabel("user", data.item.outbound_creator) }}
        </template>

        <template #cell(business_time)="data">
          {{ toDate(data.item.business_time) }}
        </template>

        <template #cell(channel_name)="data">
          {{ data.item.ext.channelName }}
        </template>


        <!-- Column: State -->
        <template #cell(is_finance)="data">
          <b-link
                  @click="changeState(data.item)"
          >
            <b-badge
                    pill
                    :variant="`light-${getCodeColor('state', data.item.is_finance)}`"
            >
              {{data.item.is_finance==1?"审核通过":"审核未通过"}}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
                  variant="link"
                  no-caret
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="toPDF(data.item.outbound_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">生成PDF</span>

            </b-dropdown-item>
            <b-dropdown-item @click="getsalesoutbounditemid(data.item)" v-b-modal.modal-select-SalesOutboundItemWarehouseList >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">出库/回执</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getsalesoutbounditemid(data.item)" v-b-modal.modal-select-SalesOutboundItemDetailedList >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">出库明细</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showFileAndMemoWindow(data.item.outbound_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">附件上传</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="user.user_id==2" @click="showSalesOutboundItemAbort(data.item.outbound_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">作废单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="returnGoods(data.item)"  @click="showReturnModal(data.item.outbound_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">生成退货单</span>
            </b-dropdown-item>

            <!--            <b-dropdown-item v-if="detailed(data.item)" v-b-modal.modal-select-stock-inbound-item>-->
            <!--              <feather-icon icon="EditIcon"/>-->
            <!--              <span class="align-middle ml-50">入库明细</span>-->
            <!--            </b-dropdown-item>-->

            <!--            <b-dropdown-item v-if="returnGoods(data.item)"-->
            <!--                             :to="{ name: 'apps-salesreturnbound-edit',query:{outbound_id:data.item.outbound_id}}">-->
            <!--              <feather-icon icon="EditIcon"/>-->
            <!--              <span class="align-middle ml-50">生成退货单</span>-->
            <!--            </b-dropdown-item>-->


          </b-dropdown>
        </template>

      </b-table>

      <b-modal
              id="modal-select-stock-inbound-item"
              ok-only
              centered
              size="lg"
      >
        <stock-inbound-item-list
                ref="myContactSelects">
        </stock-inbound-item-list>
      </b-modal>

      <b-modal
              id="modal-select-SalesOutboundItemWarehouseList"
              ok-only
              centered
              ok-title="关闭"
              size="xl"
              title="出库单"
      >
        <sales-outbound-item-warehouse-list
                ref="myUserSelects" :salesoutbounditemid="salesoutbounditemid" v-on:table="fromChildren">
        </sales-outbound-item-warehouse-list>
      </b-modal>

      <b-modal
              id="modal-select-SalesOutboundItemDetailedList"
              ok-only
              centered
              size="xl"
              title="出库单明细"
      >
        <stock-outbound-item-list
                ref="myUserSelects" :salesoutbounditemid="salesoutbounditemid">
        </stock-outbound-item-list>
      </b-modal>


      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >
          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" :userDepartment="userDepartment" v-if="modalName === '创建人' "
          >
          </user-select>

          <warehouse-list
              ref="warehouseSelect" v-else-if="modalName === '仓库'"
          >
          </warehouse-list>

        </b-modal>
        <b-modal
            id="modal-returnbound"
            ok-only
            ok-title="取消"
            centered
            size="xl"
            :title="'生成退货单'"
            ref="returnboundModal"
        >
          <sales-returnbound-edit ref="storeSelectReceive" :outbId="outbound_id" @refesh="refesh"></sales-returnbound-edit>
        </b-modal>
      </div>
    </b-card>


    <!-- 添加附件窗口 -->
    <b-modal
            id="fileModal"
            ok-only
            ok-title="确认"
            cancel-title="取消"
            centered
            size="xl"
            title="添加附件"
            ref="fileModal"
            @ok="submitAtt"
            @hidden="clearInput()"
    >

      <div>
        <b-col cols="12">
          <label>备注</label>
          <b-form-textarea v-model="contentInfo"></b-form-textarea>
        </b-col>
        <br>
        <b-col cols="12">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_wh'"
                               :id="memoFile"
                               :object_type="'sales_outbound'"
                               :object_id="0"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </div>
      <br>
    </b-modal>

    <b-modal
        id="pdfModal"
        centered
        title="PDF"
        ref="pdfModal"
        hide-footer
    >
      <attachment-upload theme="preview"
                         attachment_id="attachments"
                         :id="pdf_id"
                         :readonly="true"
                         v-if="pdf_id"
      />
      <div v-else>
        <p class="my-4">正在生成，请稍候!</p>
      </div>

    </b-modal>

    <b-modal
        id="abortModal"
        ok-only
        ok-title="确认"
        @ok="onSelectAbort"
        cancel-title="取消"
        centered
        size="lg"
        ref="abortModal"
    >
      <sales-outbound-item-abort-list
          ref="abortSelect"
          :salesOutboundId="salesOutboundId"
      >
      </sales-outbound-item-abort-list>
      <b-form-group
          label-cols="1"
          label-cols-lg="1"
          label="备注:"
          label-for="memo"
          label-size="sm"
          class="mt-1"
      >
        <b-form-input
            id="memo"
            size="sm"
            v-model="memo"
            placeholder="填写备注"
        />

      </b-form-group>

    </b-modal>

    <b-modal
        id="modal-stock-lock"
        ref="modal-stock-lock"
        no-close-on-backdrop
        cancel-title="取消"
        centered
        size="xl"
        title="锁库单据"
    >
      <stock-occupied-list
          :warehouse_id="stockOccupied.warehouse_id"
          :product_id="stockOccupied.product_id"
          :stock_id="stockOccupied.stock_id"
      >

      </stock-occupied-list>
    </b-modal>

  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import {ref, onUnmounted} from '@vue/composition-api'
  import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
  import salesoutboundUseList from './salesoutboundwarehouseUseList'
  import salesoutboundStore from './salesoutboundStore'
  import codeStore from "@/views/apps/code/codeStore";
  import StockInboundItemList from "@/views/apps/stockinbounditem/StockInboundItemList";
  import axios from "@/libs/axios";
  import {useToast} from "vue-toastification/composition";
  import salesoutbounditemwarehouseUseList from "@/views/apps/salesoutbounditem/salesoutbounditemwarehouseUseList";
  import SalesOutboundItemWarehouseList from "@/views/apps/salesoutbounditem/SalesOutboundItemWarehouseList";
  import SalesOutboundItemDetailedList from "@/views/apps/salesoutbounditem/SalesOutboundItemDetailedList";
  import StockOutboundItemList from "@/views/apps/stockoutbounditem/StockOutboundItemList";
  import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
  import {useRouter} from "@core/utils/utils";
  import UserSelect from '@/views/apps/user/user-modal/UserSelect'
  import Ripple from "vue-ripple-directive";
  import flatPickr from 'vue-flatpickr-component'
  import SalesOutboundItemAbortList
    from "@/views/apps/salesoutbounditem/salesoutbound-abort/SalesOutboundItemAbortList";
  import salesoutboundabortStore from "@/views/apps/salesoutboundabort/salesoutboundabortStore";
  import {getUserData} from "@/auth/utils";
  import XyInputButton from "@/views/components/xy/XyInputButton";
  import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
  import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
  import SalesReturnboundEdit from "@/views/apps/salesreturnbound/SalesReturnboundEdit";

  export default {
    components: {
      StockOccupiedList,
      WarehouseList,
      SalesReturnboundEdit,
      XyInputButton,
      SalesOutboundItemAbortList,
      StockOutboundItemList,
      SalesOutboundItemDetailedList,
      SalesOutboundItemWarehouseList,
      StockInboundItemList,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      useToast,
      axios,
      AttachmentUpload,
      UserSelect,
      flatPickr,
    },
    data(){
      return{
        stockOccupied:{},
        salesoutbounditemid:0,
        attachments_ids: '',
        contract: '',
        memoFile:"",
        outbound_id:"",
        uploadInfo: [],
        attachmentId: null,
        contentInfo: '',
        outboundId: null,
        fields: [
          { key: 'ext', label: '商品69码'},
          { key: 'name', label: '名称' },
          { key: 'ourwarehouse_name', label: '所在仓库' },
          { key: 'ext.productCostTax', label: '含税售价' },
          { key: 'stock_id', label: '库存id' },
          { key: 'qty', label: '计划出库数量' },
          { key: 'trueqty', label: '实际出库数量' },
          { key: 'receiveqty', label: '对方接收数量'},
          { key: 'ext.returnQty', label: '退货数量'},

        ],
        advanced_search_modal:false,
        modalName: '',
        userDepartment: "sales",
        creatorName:'',
        pdf_id: undefined,
        salesOutboundId:0,
        memo:'',
        user: {},
        warehouseName:'',
      }
    },
    directives: {
      Ripple,
    },
    methods: {
      changeState: function (data) {
        const isFinance = 1 - data.is_finance
        store.dispatch('salesoutbound/state', {id: data.id, isFinance: isFinance}).then(res => {
          this.refetchData()
        })
      },
      showFileAndMemoWindow: function (attachmentId) {
        this.$refs['fileModal'].show()
        this.attachmentId = attachmentId
      },
      clearInput: function() {
        this.uploadInfo = []
        this.contentInfo = ''
      },
      searchByCondition(){
        this.refetchData()
      },

      resetCondition() {
        this.productCode = '';
        this.orderNo = '';
        this.statusId = '';
        this.creatorName = '';
        this.creatorId = '';
        this.productName = '';
        this.outboundNo = '';
        this.business_time = '';
        this.add_time = '';
        this.channelName='';
        this.warehouseName = ''
        this.warehouseId = ''
        this.refetchData();
      },
      showSingleModal(modalName) {
        this.modalName = modalName
        this.$refs['singleModal'].show()
      },
      showReturnModal(outboundId) {
        this.outbound_id = outboundId
        this.$refs['returnboundModal'].show()
      },
      refesh(){
        this.$refs['returnboundModal'].hide()
        this.refetchData()
      },
      onSelectSingle() {
        let data = {}
        switch (this.modalName) {
          case '创建人': {
            data = this.$refs.userSelect.getSelected()
            if (data.length > 0) {
              this.creatorName = data[0].full_name;
              this.creatorId = data[0].user_id;
            }
            break;
          }
          case '仓库': {
            data = this.$refs.warehouseSelect.getSelected()
            if (data.length > 0) {
              this.warehouseName = data[0].warehouse_name;
              this.warehouseId = data[0].warehouse_id;
            }
            break;
          }
          default: {
            //statements;
            break
          }
        }
      },
    },
    setup() {
      const toast = useToast()
      // Register module
      if (!store.hasModule('salesoutbound')) store.registerModule('salesoutbound', salesoutboundStore)
      if (!store.hasModule('code')) store.registerModule('code', codeStore)
      if (!store.hasModule('salesoutboundabort')) store.registerModule('salesoutboundabort', salesoutboundabortStore)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('salesoutbound')) store.unregisterModule('salesoutbound')
        if (store.hasModule('code')) store.unregisterModule('code')
        if (store.hasModule('salesoutboundabort')) store.unregisterModule('salesoutboundabort')
      })

      const router = useRouter()

      const change = function (val) {
        if (val.status === 0 || val.status === 1 || val.status === 2) return true
        else return false
      }

      const submit = function (val) {
        if (val.status === 0) return true
        else return false
      }

      const detailed = function (val) {
        if (val.status === 2) return true
        else return false
      }

      const returnGoods = function (val) {
        if (val.status === 3) return true
        else return false
      }


      const submitWare = function (val) {
        var res = confirm("是否提交仓库？")
        if (res) {
          store
                  .dispatch('salesoutbound/submitwarehouse',
                          {
                            id: val.outbound_id
                          }
                  )
                  .then(res => {
                    if(res.data.code==0){
                      toast.success("操作成功!")
                      refetchData()
                    }else {
                      toast.error(res.data.data)
                    }

                  })
                  .catch((e) => {
                    toast.error("操作失败!")
                    refetchData()
                  })
        }
      }

      const getsalesoutbounditemid = function (params) {
        this.salesoutbounditemid = params.id
      }

      const fromChildren = function (val) {
        refetchData()
        // console.log("这是从子页面传来的", this.salesOrderItem)
      }

      const onUploaded = function (id, attachment, result) {
        this.uploadInfo = attachment
      }

      const submitAtt = function() {
        let fileArr = []
        for(let i = 0; i < this.uploadInfo.length; i++) {
          fileArr.push(this.uploadInfo[i].attachment_id)
        }
        store.dispatch('salesoutbound/updateWhInfo', {
          'outboundId': this.attachmentId,
          'attachmentsWh': JSON.stringify(fileArr).replace("]", "").replace("[", ""),
          'memoWh': this.contentInfo,
        }).then(res => {
          if (res.status === 200) {
            toast.success('提交成功')
            refetchData()
          }
        })
      }

      const advanced_search = function () {
        this.advanced_search_modal = this.advanced_search_modal ? false : true;
      }

      const toPDF = function (id){
        this.pdf_id = undefined
        this.$refs['pdfModal'].show()
        store.dispatch('salesoutbound/generateSalesOutboundTodo', {id: id})
            .then(res => {
              if (res.data.code === 0) {
                this.pdf_id = res.data.data.id
              } else {
                this.$refs['pdfModal'].hide()
                toast.error(res.data.data)
              }
            })
      }

      const showSalesOutboundItemAbort = function (params) {
        this.memo = ''
        this.salesOutboundId = params
        this.$refs['abortModal'].show()
      }

      const onSelectAbort = function () {
       const data = this.$refs.abortSelect.getChecked()
        if (data.length>0){
          const itemIdArray = []
          for (let i = 0; i < data.length; i++) {
            itemIdArray.push(data[i].outbounditem_id)
          }
          const itemIds = itemIdArray.join(",")
          store.dispatch('salesoutboundabort/createSalesOutboundAbort', {itemIds: itemIds,outboundId:data[0].outbound_id,memo:this.memo,orderId:data[0].order_id})
              .then(res => {
                if (res.data.code === 0) {
                  toast.success("成功!")
                  refetchData()
                } else {
                  toast.error(res.data.data)
                }
              })
        }
      }

      const {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        productCode, orderNo, statusId,productName,creatorId,outboundNo,business_time,channelName,add_time,warehouseId,
        refetchData,

        // UI
      } = salesoutboundUseList({
        outbound_id:router.route.value.query.outbound_id
      })

      return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        productCode, orderNo, statusId,productName,creatorId,outboundNo,business_time,channelName,add_time,warehouseId,
        refetchData,

        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        returnGoods,
        toTime, toDate,
        // UI
        change,
        submit,
        detailed,
        submitWare,
        getsalesoutbounditemid,
        fromChildren,
        onUploaded,
        submitAtt,
        advanced_search,
        toPDF,
        showSalesOutboundItemAbort,
        onSelectAbort,
      }
    },
    created() {
      const userData = getUserData()
      this.user = userData
    }
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
