<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
<!--            <label>显示</label>-->
<!--            <v-select-->
<!--              v-model="limit"-->
<!--              :options="limitOptions"-->
<!--              :clearable="false"-->
<!--              class="per-page-selector d-inline-block mx-50"-->
<!--            />-->
<!--            <label>条</label>-->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
<!--            <div class="d-flex align-items-center justify-content-end">-->
<!--              <b-form-input-->
<!--                v-model="searchKey"-->
<!--                class="d-inline-block mr-1"-->
<!--                placeholder="搜索关键字..."-->
<!--              />-->
<!--              <b-button-->
<!--                variant="primary"-->
<!--                :to="{ name: 'apps-stockoutbounditem-edit'}"-->
<!--              >-->
<!--                <span class="text-nowrap">添加</span>-->
<!--              </b-button>-->
<!--            </div>-->
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(code)="data">
          {{ data.item.ext.code === '未知' ? '' : data.item.ext.code }}
        </template>

        <template #cell(name)="data">
          #[{{ data.item.product_id }}]{{ data.item.name }}{{ isGift(data.item.is_gift) }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '<br>'+'负责人：'+data.item.our_contact+
              '<br>'+'负责人电话：'+data.item.our_mobile+
              '<br>'+'地址：'+data.item.our_location+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(salesoutbounditem_type)="data">
          {{ getCodeLabel('salesoutbounditem_type', data.item.salesoutbounditem_type) }}
        </template>

        <template #cell(delivery_time)="data">
          {{ toTime(data.item.delivery_time) }}
        </template>

        <template #cell(arrival_time)="data">
          {{ toTime( data.item.arrival_time) }}
        </template>


        <!-- Column: State -->
        <template #cell(warestatus)="data">
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('stock_status', data.item.warestatus === '良品' ? 0 : 1)}`"
          >
            {{ getCodeLabel("stock_status", data.item.warestatus === '良品' ? 0 : 1)}}
          </b-badge>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-stockoutbounditem-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-stockoutbounditem-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BAlert, BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import stockoutbounditemUseList from './stockoutbounditemUseList'
import stockoutbounditemStore from './stockoutbounditemStore'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BAlert, BIcon, BootstrapVueIcons,
    VBTooltip,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('stockoutbounditem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: ["salesoutbounditemid"],
  setup(props) {
    // Register module
    if (!store.hasModule('stockoutbounditem')) store.registerModule('stockoutbounditem', stockoutbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockoutbounditem')) store.unregisterModule('stockoutbounditem')
    })

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stockoutbounditemUseList(props.salesoutbounditemid)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      isGift,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
